.popover-holder {
  display: inline-block;
}

.popover-base {
  z-index: 2501;
  display: table;
}

.popoverObj {
  max-width: 276px;
  padding: 1px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.42857143;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0,0,0,.2);

  &.darkbox-opts {
    background: none;

    ul {
      padding: 0;
      margin: 0;
    }
  }
}


.popoverObj .popover-content {
    padding: 9px 14px;
}

.popoverObj .popover-content h4 {
    margin: 0;
    display: block;
    font-weight: normal;
}

.popoverObj .popover-title {
    padding: 14px;
    font-size: 14px;
    background: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
}

.perm-explain {
    border: none;
}

.perm-explain .popover-content {
    padding: 0;
}

.perm-explain .popover-content > div {
    padding: 13px 13px 0 13px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    /*border-bottom: 1px solid #f4f4f4;*/
}

.perm-explain .popover-content > div:first-child {
    /*padding-bottom: 0;*/
}

.perm-explain .popover-content > div:last-child {
    padding-bottom: 13px;
}

.perm-explain h4 {
    color: #16B7C9;
    font-size: 13px;
}

.popover-tip {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.right .popover-tip {
    left: -8px;
    border-width: 8px 8px 8px 0;
    border-right-color: #fff;
}

.vcenter .popover-tip {
    top: 50%;
    margin-top: -8px;
}

.left .popover-tip {
    right: -8px;
    border-width: 8px 0 8px 8px;
    border-left-color: #fff;
}
